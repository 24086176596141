<template>
  <div>
    <el-dialog
        title="确认到账"
        width="800px"
        height="1000px"
        :close-on-click-modal="false"
        :visible.sync="state"
        :modal-append-to-body="false"
        style="padding: 0;"
        @close="close"
        center>
      <div >
        <el-form :model="form" ref="form">
          <el-divider style="font-size: 28px;" content-position="left">
            客户 {{ proceeds.name }} {{ proceeds.spareMoney }} 元的收款中</el-divider>

          <!-- 下面的10 10 4 是把一行分成24格，然后占对应格数 -->
          <el-row>

            <el-col :span="10">
              <el-form-item label="手续费为" label-width="120px" >
                <el-input v-model="form.charge" type="number" placeholder="请填写手续费"
                          @input="chargeChange()"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item label="元，到账金额为 " label-width="120px" >
                <el-input v-model.number="form.receivedAmount" placeholder="到账金额"
                          :readonly="true" :disabled="true">
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-form-item label-width="50px" label="元。"></el-form-item>
            </el-col>

          </el-row>

          <el-row :gutter="20">

            <!-- 下面的10 10 4 是把一行分成24格，然后占对应格数 -->
            <el-col :span="10">
              <el-form-item label="到账账户:" label-width="120px" >
                <el-select placeholder="请选到账账户" v-model="form.receivedAccountId" style="width: 100%;">
                  <el-option
                      v-for="item in accountArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                      @click.native="receivedAccountOnConfirm(item)">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item label="到账时间:" prop="receivedDate" label-width="80px" >
                <el-date-picker
                    v-model="form.receivedDate"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    placeholder="选择到账时间"
                    align="right"
                    :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-form-item label-width="20px">
                <el-button type="primary" @click="proceedsEdit">确认</el-button>
              </el-form-item>
            </el-col>

          </el-row>
        </el-form>

      </div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "confirm-proceeds",
  inject: ['proceeds'],
  created() {
    this.pageInit()
  },
  props: {
    confirmProceedsState: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      state: this.confirmProceedsState,
      form: {
        //如果没有到账时间,则 手续费置为0、到账金额置为收款金额、到账时间置为此刻
        charge: [null, '', undefined].includes(this.proceeds.receivedDate) ? 0 : this.proceeds.charge,
        receivedAmount: [null, '', undefined].includes(this.proceeds.receivedDate) ? this.proceeds.spareMoney : this.proceeds.receivedAmount,
        receivedDate: [null, '', undefined].includes(this.proceeds.receivedDate) ? this.$dateUtils.getTimeStr('s')
                        : this.proceeds.receivedDate + ":00", //收款列表页面上的时间精确到分钟，这边补上00秒
        receivedAccountId: this.proceeds.receivedAccountId,
        receivedAccountName: this.proceeds.receivedAccountName,
      },

      accountArray: [],

      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    pageInit() {
      /* 查公司结算方式参数 */
      this.$selectUtils.queryAccountIds().then(response => {
        this.accountArray = JSON.parse(response.data.data)
      })

    },

    proceedsEdit() {
      if ([null, '', undefined].includes(this.form.receivedAccountId)) {
        this.$alert('请选择到账账户', '', {
          type: "warning",
          confirmButtonText: '确定',
        });
        return false
      }

      if ([null, '', undefined].includes(this.form.receivedDate)) {
        this.$alert('请选择到账时间', '', {
          type: "warning",
          confirmButtonText: '确定',
        });
        return false
      }

      if ([null, '', undefined].includes(this.form.charge)
          || !(0 <= parseFloat(this.form.charge) && parseFloat(this.form.charge) <= this.proceeds.spareMoney)) {
        this.$alert('请填写手续费，手续费的范围是[0, ' + this.proceeds.spareMoney + ']', '', {
          type: "warning",
          confirmButtonText: '确定',
        });
        return false
      }

      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$alert('确认信息无误？', "确认到账", {
            dangerouslyUseHTMLString: true,
            type: "warning",
            showCancelButton: true
          }).then(() => {
            this.$axios({
              method: 'post',
              url: "/proceeds/updateProceeds/",
              params: {
                ...this.form,
                proceedsId: this.proceeds.id,
                orderId: this.proceeds.orderId,
              }
            }).then(response => {
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? '提交成功' : response.data.msg,
                type: flag ? 'success' : 'error',
                duration: 1000,
              });
              if (flag) {
                this.close();
              }
            })
          })
        }
      })
    },

    //当手续费改动时，触发这个函数，自动计算 到账金额。
    chargeChange: function() {
      this.form.receivedAmount = this.proceeds.spareMoney - this.form.charge;
    },

    receivedAccountOnConfirm: function (item) {
      this.form.receivedAccountId = item.value;
      this.form.receivedAccountName = item.name;
    },

  }
}
</script>

<style scoped>
/deep/ .el-dialog__body{
  padding-top: 0;
}
</style>