<template>
  <div>
    <el-dialog
        title="收款编辑"
        width="43%"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <div>
        <el-form label-width="110px" :model="form" :rules="rules" ref="form">
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="收款项目" prop="proceedsName">
                <el-select placeholder="请选择收款项目" v-model="form.proceedsName" style="width: 100%;">
                  <el-option
                      v-for="item in proceedsNameArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="收款金额" prop="spareMoney">
                <el-input v-model.number="form.spareMoney" placeholder="收款金额"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="收款人" prop="payee">
                <el-select placeholder="请选择收款人" v-model="form.payee" style="width: 100%;" ref="service">
                  <el-option
                      v-for="item in payeeArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="收款方式" prop="payment">
                <el-cascader
                    :props="{expandTrigger: 'hover',
                            label: 'name'}"
                    multiple="false"
                    :show-all-levels="false"
                    style="width: 100%;"
                    :options="paymentArray"
                    v-model="form.payment"
                    ref="payment"
                    @change="cascaderClsoe"></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider content-position="left"></el-divider>
        <el-row>
          <el-col :span="7" :offset="1">
            客户名：{{proceeds.name}}
          </el-col>
          <el-col :span="7">
            订单总价：{{proceeds.orderPrice}}
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="proceedsEdit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "proceeds-edit",
  inject: ['proceeds'],
  created() {
    this.pageInit()
  },
  props: {
    proceedsEditState: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      state:this.proceedsEditState,
      form: {
        proceedsName: this.proceeds.proceedsNameId,
        spareMoney: this.proceeds.spareMoney,
        payee: this.proceeds.payeeId,
        createDate: this.proceeds.createDate,
        payment: this.proceeds.paymentId,
      },
      rules: {
        proceedsName: [{required: true, message: '请选择收款项目', trigger: 'change'}],
        spareMoney: [{required: true, message: '请输入收款金额', trigger: 'blur'}],
        payee: [{required: true, message: '请选择收款人', trigger: 'change'}],
      },
      proceedsNameArray: [],
      payeeArray: [],
      paymentArray: [],
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    pageInit() {
      this.$selectUtils.queryOrderProceedsProjects().then(response => {
        this.proceedsNameArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryPayeeIds().then(response => {
        this.payeeArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryPaymentIds().then(response => {
        this.paymentArray = JSON.parse(response.data.data);
      })
    },
    cascaderClsoe(val) {
      this.form.payment = val.length===1?val[0]:val.length===2?val[1]: "",
      this.$refs.payment.dropDownVisible = false;
    },
    proceedsEdit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$alert('确认要修改收款这条金额吗？', "修改收款", {
            dangerouslyUseHTMLString: true,
            type: "warning",
            showCancelButton: true
          }).then(() => {
            this.$axios({
              method: 'post',
              url: "/proceeds/updateProceeds/",
              params: {
                ...this.form,
                proceedsId: this.proceeds.id,
                orderId: this.proceeds.orderId,
              }
            }).then(response => {
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? '修改收款成功': response.data.msg,
                type: flag ? 'success':'error',
                duration: 1000,
              });
              if (flag) {
                this.close();
              }
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>